@import "../../_theme_variables";

/* Block newsletter */
#columns #newsletter_block_left {
	.form-group {
		margin-bottom: 0;
		.form-control {
			max-width: 222px;
			display: inline-block;
			margin-right: 6px;
			@media (min-width: $screen-sm) and (max-width: $screen-md-max) { // min 768px max 1199px
				margin-bottom: 10px;
				margin-right: 0;
			}
		}
	}
	.success_inline, .warning_inline {
		text-align: left;
		padding: 1px 0 0 0;
		margin-bottom: -19px;	
	}
	.success_inline	{
		color: #418B19;
	}
	.warning_inline {
		color: #f13340;
	}
}

/* Block newsletter footer */
#footer {
	#newsletter_block_left {
		overflow: hidden;
		width: 50%;
		float: left;
		padding: 13px 15px 7px 15px;
		margin-top:-14px;
		margin-bottom: 0;
		@media (max-width: $screen-xs-max) { // max 767px
			width: 100%;
		}
		h4 {
			color:  #888;
			background: none;
			float: left;
			padding: 7px 16px 5px 0;
			text-transform: none;
			font-size: 18px;
			font-weight: 500;
			line-height: 25px;
			border: none;
			&:after {
				display: none;
			}
		}
		.block_content {
			overflow: hidden;
		}
		.form-group {
			margin-bottom: 0;
			.form-control {
				height: 45px;
				line-height: 30px;
				max-width: 267px;
				background: #3c3c3c;
				border-color: #515151;
				color: $light-text-color;
				padding: 5px 43px 5px 12px;
				display: inline-block;
				float: left;
				&:focus {
					@include box-shadow(black 0 0 0);
				}
			}
			.btn-sm {
				margin-left: -43px;
				border: none;
				background: none;
				text-align: center;
				color: #908f8f;
				padding: 8px;
				&:before {
					content: "\f138";
					font-family: $font-icon;
					font-size: 28px;
					line-height: 28px;
				}
				&:hover {
					color: $light-text-color !important;
				}
				span{
					display: none;
				}	
			}
		}
		.warning_inline {
			display: block;
			color: #f13340;
			font-size: 13px;
			line-height :26px;
			clear: both;
			@media (min-width: $screen-lg) { // min 1200px
				display: inline-block;
				position: relative;
				top: -35px;
				margin-bottom: -35px;
				left: 15px;
				clear: none;
			}
		}
		.newsletter-input {
  			max-width: 300px !important;
  		}
	}
}